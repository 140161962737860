import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Mail, User, Phone, Lock, AlertCircle, Eye, EyeOff } from "lucide-react";
import FAQ from "../Component/FAQ";
import LoadingSpinner from "../utils/LoadingSpinner";
import SnackBar from "../utils/SnackBar";
import { OnlyTextValidation, EmailValidation, EmptyFieldValidation, PasswordValidation } from "../Functions/Validations";
import { signupUser } from '../api/authApi';
import { encrypt } from "../Functions/encryption";

const SignUpPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackBarVisible, setSnackbarVisible] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [details, setDetails] = useState({
    fullname: "",
    email: "",
    mobile: "",
    password: ""
  });
  const [errors, setErrors] = useState({
    fullname: "",
    email: "",
    mobile: "",
    password: ""
  });

  const showSnackbar = (message, duration = 3000) => {
    setSnackMessage(message);
    setSnackbarVisible(true);
    setTimeout(() => {
      setSnackbarVisible(false);
      setSnackMessage("");
    }, duration);
  };

  const validateField = async (name, value) => {
    try {
      let error = "";
      if (!value) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
      } else {
        switch (name) {
          case 'fullname':
            await OnlyTextValidation(['fullname'], { fullname: value });
            break;
          case 'email':
            await EmailValidation(value);
            break;
          case 'mobile':
            if (!/^\d{10}$/.test(value)) {
              throw new Error('Mobile number must be 10 digits');
            }
            break;
          case 'password':
            await PasswordValidation(value);
            break;
          default:
            break;
        }
      }
      setErrors(prev => ({ ...prev, [name]: error }));
      return !error;
    } catch (err) {
      setErrors(prev => ({ ...prev, [name]: err.message }));
      return false;
    }
  };

  const handleDetails = async (event) => {
    const { name, value } = event.target;
    setDetails(prev => ({
      ...prev,
      [name]: value
    }));
    await validateField(name, value);
  };

  const CreateNewUser = async () => {
    const { fullname, email, mobile, password } = details;
    try {
      // Validate all fields first
      const isFullnameValid = await validateField("fullname", fullname);
      const isEmailValid = await validateField("email", email);
      const isMobileValid = await validateField("mobile", mobile);
      const isPasswordValid = await validateField("password", password);
      
      if (!isFullnameValid || !isEmailValid || !isMobileValid || !isPasswordValid) {
        showSnackbar("Please fix all errors before continuing");
        return;
      }

      setIsLoading(true);
      await EmptyFieldValidation(["fullname", "email", "mobile", "password"], details);
      
      const result = await signupUser(fullname, email, mobile, password);
      
      if (!result || !result.uid) {
        throw new Error("Failed to create account. Please try again.");
      }

      // Only navigate if we have a valid result
      navigate("/verify-new-user", {
        replace: true,
        state: { 
          nextScreen: "/ask-vetiq",
          email,
          uid: result.uid,
          userData: {
            accessToken: encrypt(result.accessToken),
            refreshToken: result.refreshToken,
            sessionId: result.sessionId,
            uid: result.uid
          }
        },
      });
    } catch (err) {
      // Ensure the error message is a string
      const errorMessage = err?.message || "An error occurred while creating your account";
      showSnackbar(errorMessage);
      console.error("Signup error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center p-4 md:pt-24 gap-4 bg-slate-50 min-h-screen">
      {isSnackBarVisible && (
        <SnackBar 
          isSnackBarVisible={isSnackBarVisible} 
          snackMessage={snackMessage}
        />
      )}
      
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-full">
            <LoadingSpinner className="h-12 w-12 animate-spin" />
          </div>
        </div>
      )}

      <div className="flex flex-col md:flex-row items-start max-w-6xl w-full gap-6">
        {/* Sign Up Form */}
        <div className="md:w-1/2 w-full bg-white p-6 rounded-lg shadow-sm">
          <div className="pb-6">
            <h2 className="text-2xl font-bold text-slate-900">Create Your Account</h2>
            <p className="text-gray-600 mt-2">
              Already have an account?{" "}
              <Link to="/login" className="text-[#7F55E0] hover:text-[#6A44C0] font-medium">
                Sign in here
              </Link>
            </p>
          </div>

          <div className="space-y-6">
            {/* Full Name Input */}
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Full Name
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User size={20} className="text-gray-500" />
                </div>
                <input
                  required
                  type="text"
                  name="fullname"
                  value={details.fullname}
                  onChange={handleDetails}
                  placeholder="Enter your full name"
                  className={`w-full pl-10 pr-3 py-2 border ${
                    errors.fullname ? 'border-red-500' : 'border-gray-300'
                  } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7F55E0] focus:border-transparent transition-colors
                  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                />
              </div>
              {errors.fullname && (
                <div className="flex items-center gap-2 text-red-500 mt-1">
                  <AlertCircle size={16} />
                  <p className="text-sm">{errors.fullname}</p>
                </div>
              )}
            </div>

            {/* Mobile Input */}
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Mobile Number
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Phone size={20} className="text-gray-500" />
                </div>
                <input
                  required
                  type="tel"
                  name="mobile"
                  value={details.mobile}
                  onChange={handleDetails}
                  placeholder="Enter your mobile number"
                  className={`w-full pl-10 pr-3 py-2 border ${
                    errors.mobile ? 'border-red-500' : 'border-gray-300'
                  } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7F55E0] focus:border-transparent transition-colors
                  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                  maxLength={10}
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              </div>
              {errors.mobile && (
                <div className="flex items-center gap-2 text-red-500 mt-1">
                  <AlertCircle size={16} />
                  <p className="text-sm">{errors.mobile}</p>
                </div>
              )}
            </div>

            {/* Email Input */}
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail size={20} className="text-gray-500" />
                </div>
                <input
                  required
                  type="email"
                  name="email"
                  value={details.email}
                  onChange={handleDetails}
                  placeholder="Enter your email"
                  className={`w-full pl-10 pr-3 py-2 border ${
                    errors.email ? 'border-red-500' : 'border-gray-300'
                  } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7F55E0] focus:border-transparent transition-colors
                  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                />
              </div>
              {errors.email && (
                <div className="flex items-center gap-2 text-red-500 mt-1">
                  <AlertCircle size={16} />
                  <p className="text-sm">{errors.email}</p>
                </div>
              )}
            </div>

            {/* Password Input */}
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock size={20} className="text-gray-500" />
                </div>
                <input
                  required
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={details.password}
                  onChange={handleDetails}
                  placeholder="Create a password"
                  className={`w-full pl-10 pr-10 py-2 border ${
                    errors.password ? 'border-red-500' : 'border-gray-300'
                  } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7F55E0] focus:border-transparent transition-colors
                  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 hover:text-gray-700"
                  disabled={isLoading}
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
              {errors.password && (
                <div className="flex items-center gap-2 text-red-500 mt-1">
                  <AlertCircle size={16} />
                  <p className="text-sm">{errors.password}</p>
                </div>
              )}
            </div>

            <button
              onClick={CreateNewUser}
              disabled={isLoading || Object.values(errors).some(error => error)}
              className={`w-full py-3 px-6 rounded-lg text-white font-medium text-lg
                transition duration-300 ${
                  isLoading || Object.values(errors).some(error => error)
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-[#7F55E0] hover:bg-[#6A44C0] transform hover:-translate-y-0.5'
                }`}
            >
              {isLoading ? (
                <div className="flex items-center justify-center gap-2">
                  <LoadingSpinner className="h-5 w-5" />
                  <span>Creating account...</span>
                </div>
              ) : (
                'Create Account'
              )}
            </button>
          </div>
        </div>

        {/* Benefits Section */}
        <div className="md:w-1/2 w-full space-y-6">
          <h2 className="text-2xl md:text-3xl font-bold text-slate-900">
            AI-powered preventive care keeps your pet healthy and detects issues early.
          </h2>
          <p className="text-lg text-gray-600">
            Your pet may seem healthy, but hidden dangers lurk. Poor diet triggers obesity, silent symptoms escalate, and late diagnoses lead to costly, less effective treatments.
          </p>
          <div className="grid gap-4">
            {[
              { title: "Silent Weight Gain", desc: "Leads to obesity & diabetes." },
              { title: "Hidden Infections", desc: "Go unnoticed until they worsen." },
              { title: "Delayed Diagnosis", desc: "Costs more & risks your pet's health." },
            ].map((item, index) => (
              <div
                key={index}
                className="p-4 bg-white rounded-lg shadow-sm border border-gray-100 hover:border-[#7F55E0] transition-colors"
              >
                <strong className="text-[#7F55E0]">{item.title} – </strong>
                <span className="text-gray-600">{item.desc}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="max-w-6xl w-full mt-12">
        <FAQ />
      </div>
    </div>
  );
};

export default SignUpPage;
