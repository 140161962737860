import React, { useState, useEffect } from "react";
import { Clock, AlertCircle } from "lucide-react";
import Pressable from "../utils/Pressable";

const VerifyOtp = ({ 
  SendVerificationEmail,
  inputRefs,
  setOtp,
  otp,
  resendEnabled,
  setResendEnabled,
  heading,
  subheading,
  handleSubmit,
  isLoading 
}) => {
  const [timer, setTimer] = useState(30);
  const [error, setError] = useState("");

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer((prev) => prev - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setResendEnabled(true);
    }
  }, [timer, setResendEnabled]);

  const handleResend = () => {
    if (!isLoading) {
      setTimer(30);
      setResendEnabled(false);
      setError("");
      SendVerificationEmail();
    }
  };

  const handleChange = (index, value) => {
    if (isLoading) return;
    setError("");

    if (/[^0-9]/.test(value)) return;
    if (value.length > 1) return;

    const newOtp = otp.split("");
    newOtp[index] = value;
    setOtp(newOtp.join(""));

    if (value.length === 1 && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleBackspace = (index, e) => {
    if (isLoading) return;
    
    if (e.key === "Backspace") {
      if (!otp[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
      setError("");
    }
  };

  const handlePaste = (e) => {
    if (isLoading) return;
    e.preventDefault();
    
    const pastedData = e.clipboardData.getData("text").slice(0, 6);
    if (/^\d+$/.test(pastedData)) {
      setOtp(pastedData);
      const focusIndex = Math.min(pastedData.length, 5);
      inputRefs.current[focusIndex]?.focus();
    }
  };

  const localHandleSubmit = () => {
    if (otp.length !== 6) {
      setError("Please enter a complete 6-digit OTP");
      return;
    }
    handleSubmit();
  };

  return (
    <div className="text-center">
      <h2 className="text-2xl font-bold text-[#4A2E8C] mb-4">{heading}</h2>
      <p className="text-gray-600 mb-6 text-center">{subheading}</p>
      
      <div className="space-y-6">
        <div className="flex space-x-2 mb-6 justify-center">
          {Array.from({ length: 6 }).map((_, index) => (
            <input
              key={index}
              type="text"
              value={otp[index] || ""}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleBackspace(index, e)}
              onPaste={handlePaste}
              ref={(ref) => (inputRefs.current[index] = ref)}
              maxLength="1"
              className={`w-12 h-12 text-center text-xl font-semibold border-2 
                ${error ? 'border-red-500' : 'border-gray-300'} 
                rounded-md focus:outline-none focus:border-[#7F55E0]
                ${isLoading ? 'opacity-50 cursor-not-allowed bg-gray-50' : 'bg-white'}
                transition-all duration-200`}
              inputMode="numeric"
              pattern="[0-9]*"
              disabled={isLoading}
              placeholder="•"
            />
          ))}
        </div>

        {error && (
          <div className="flex items-center justify-center gap-2 text-red-500">
            <AlertCircle size={16} />
            <p className="text-sm">{error}</p>
          </div>
        )}

        <Pressable 
          onClick={localHandleSubmit}
          disabled={isLoading || otp.length !== 6}
          title={isLoading ? "Verifying..." : "Submit"}
          className={`w-full md:w-1/2 mt-4 text-xl text-white py-3 px-6 rounded-md transition duration-300
            ${isLoading || otp.length !== 6 
              ? 'bg-gray-400 cursor-not-allowed' 
              : 'bg-[#7F55E0] hover:bg-[#6A44C0]'}`}
        />

        <div className="mt-4">
          {resendEnabled ? (
            <Pressable
              onClick={handleResend}
              disabled={isLoading}
              title="Didn't Get A Code? Resend"
              className={`text-xl hover:text-[#6A44C0] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            />
          ) : (
            <div className="flex items-center justify-center gap-2 text-gray-600">
              <Clock size={16} />
              <span>Resend OTP in {timer}s</span>
            </div>
          )}
        </div>

        <div className="flex items-center justify-center gap-2 text-amber-600 text-sm mt-4">
          <AlertCircle size={16} />
          <p>Please do not close this window or press back</p>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
