import React, { useState, useRef, useEffect, useContext } from "react";
import { Mail, X, AlertCircle, ArrowLeft } from "lucide-react";
import VerifyOtp from "../Component/VerifyOtp";
import { OtpValidation } from "../Functions/Validations";
import { useNavigate, useLocation } from "react-router-dom";
import { sendEmailVerificationOtp, verifyEmailOtp } from '../api/authApi';
import { storeUserData } from '../localuserdata/indexedDB';
import { UserContext } from '../App';
import LoadingSpinner from '../utils/LoadingSpinner';
import SnackBar from '../utils/SnackBar';

const VerifyNewUserPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const inputRefs = useRef([]);
    const { nextScreen, email, uid, userData } = location.state;
    const [resendEnabled, setResendEnabled] = useState(false);
    const [isSnackBarVisible, setSnackBarVisible] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const emailSentRef = useRef(false);

    const showSnackbar = (message, duration = 3000) => {
        setSnackMessage(message);
        setSnackBarVisible(true);
        setTimeout(() => {
            setSnackBarVisible(false);
            setSnackMessage('');
        }, duration);
    };

    const SendVerificationEmail = async () => {
        if (emailSentRef.current) return;
        
        try {
            setIsLoading(true);
            const verification_mode = 'email';
            const verification_title = 'new_user_email_verification';
            const subject = 'Verify Your Email For Poodles Petcare';
            
            await sendEmailVerificationOtp(email, uid, verification_mode, verification_title, subject);
            emailSentRef.current = true;
            showSnackbar('Verification email sent successfully!');
            setResendEnabled(false);
            setTimeout(() => setResendEnabled(true), 30000); // Enable resend after 30 seconds
        } catch (err) {
            console.error('Failed to send verification email:', err);
            showSnackbar('Failed to send verification email. Please try again.');
            emailSentRef.current = false;
        } finally {
            setIsLoading(false);
        }
    };

    const handleVerifyOtp = async () => {
        try {
            setIsLoading(true);
            await OtpValidation(otp);
            await verifyEmailOtp(uid, otp);
            await storeUserData(userData);
            
            setUser(uid);
            showSnackbar('Email verified successfully! Redirecting...', 2000);
            
            setTimeout(() => {
                navigate(nextScreen, { replace: true });
            }, 2000);
        } catch (err) {
            console.error('OTP verification failed:', err);
            
            let errorMessage = 'OTP verification failed. Please try again.';
            if (err.message.includes('Otp is invalid')) {
                errorMessage = 'Invalid OTP! Please enter the correct OTP.';
            } else if (err.message.includes('Otp has expired')) {
                errorMessage = 'OTP has expired! Please request a new OTP.';
                emailSentRef.current = false;
                setResendEnabled(true);
            }
            
            showSnackbar(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        SendVerificationEmail();
        return () => {
            emailSentRef.current = false;
        };
    }, []);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            {/* Snackbar */}
            {isSnackBarVisible && (
                <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-[60]">
                    <SnackBar 
                        isSnackBarVisible={isSnackBarVisible} 
                        snackMessage={snackMessage}
                    />
                </div>
            )}

            <div className="w-full max-w-md bg-white rounded-lg shadow-xl relative">
                {/* Header with Close Button */}
                <div className="flex justify-between items-start p-6 pb-0">
                    <div className="flex-1">
                        <div className="flex justify-center">
                            <div className="w-16 h-16 bg-[#F0EBFF] rounded-full flex items-center justify-center">
                                <Mail size={32} className="text-[#7F55E0]" />
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={() => navigate('/login', { replace: true })}
                        className="text-gray-400 hover:text-gray-600 transition-colors"
                        disabled={isLoading}
                    >
                        <X size={24} />
                    </button>
                </div>

                <div className="p-6">
                    <div className="text-center mb-8">
                        <h2 className="text-2xl font-bold text-slate-900 mb-2">Verify Your Email</h2>
                        <p className="text-gray-600">
                            We've sent a verification code to <span className="font-medium text-slate-900">{email}</span>. 
                            Please enter it below to verify your account.
                        </p>
                    </div>

                    {/* Verification Form */}
                    <VerifyOtp
                        setOtp={setOtp}
                        SendVerificationEmail={SendVerificationEmail}
                        inputRefs={inputRefs}
                        otp={otp}
                        resendEnabled={resendEnabled}
                        setResendEnabled={setResendEnabled}
                        handleSubmit={handleVerifyOtp}
                        heading=""
                        subheading=""
                        isLoading={isLoading}
                    />

                    <div className="mt-6 flex items-center justify-center">
                        <button
                            onClick={() => navigate('/login', { replace: true })}
                            className="text-gray-500 hover:text-gray-700 flex items-center gap-2 text-sm font-medium transition-colors"
                            disabled={isLoading}
                        >
                            <ArrowLeft size={16} />
                            Back to Login
                        </button>
                    </div>
                </div>
            </div>

            {/* Loading Overlay */}
            {isLoading && (
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[70]">
                    <div className="bg-white p-4 rounded-full shadow-xl">
                        <LoadingSpinner className="h-12 w-12 animate-spin" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default VerifyNewUserPage;
