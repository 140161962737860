import React, { useState } from "react";
import { X, Mail, AlertCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { EmptyFieldValidation, EmailValidation } from "../Functions/Validations";
import TextInput from "../utils/TextInput";
import Pressable from "../utils/Pressable";
import { checkEmailExists } from '../api/authApi';
import LoadingSpinner from '../utils/LoadingSpinner';
import SnackBar from '../utils/SnackBar';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isSnackBarVisible, setSnackBarVisible] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const showSnackbar = (message, duration = 3000) => {
        setSnackMessage(message);
        setSnackBarVisible(true);
        setTimeout(() => {
            setSnackBarVisible(false);
            setSnackMessage('');
        }, duration);
    };

    const validateEmail = (email) => {
        if (!email) {
            setError('Email is required');
            return false;
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            setError('Please enter a valid email address');
            return false;
        }
        setError('');
        return true;
    };

    const handleSubmit = async () => {
        if (!validateEmail(email)) return;

        try {
            setIsLoading(true);
            await EmptyFieldValidation(["email"], { email });
            await EmailValidation(email);
            
            const userCheckResponse = await checkEmailExists(email);
            showSnackbar('Verification email will be sent shortly');
            
            setTimeout(() => {
                navigate("/verify-for-pass-req", {
                    replace: true,
                    state: {
                        nextScreen: "/reset-password",
                        email: email,
                        uid: userCheckResponse.uid
                    },
                });
            }, 1000);
        } catch (err) {
            showSnackbar(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            {isSnackBarVisible && (
                <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-[60]">
                    <SnackBar 
                        isSnackBarVisible={isSnackBarVisible} 
                        snackMessage={snackMessage}
                    />
                </div>
            )}

            <div className="w-full max-w-md bg-white rounded-lg shadow-xl relative">
                {/* Close Button */}
                <button
                    onClick={() => navigate('/login')}
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
                    disabled={isLoading}
                >
                    <X size={24} />
                </button>

                <div className="p-6">
                    {/* Email Icon */}
                    <div className="flex justify-center mb-6">
                        <div className="w-16 h-16 bg-[#F0EBFF] rounded-full flex items-center justify-center">
                            <Mail size={32} className="text-[#7F55E0]" />
                        </div>
                    </div>

                    <div className="text-center mb-6">
                        <h2 className="text-2xl font-bold text-slate-900 mb-2">Forgot Your Password?</h2>
                        <p className="text-gray-600">
                            Enter your email address and we'll send you a verification code to reset your password.
                        </p>
                    </div>

                    <div className="space-y-4">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Mail size={20} className="text-gray-500" />
                            </div>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    validateEmail(e.target.value);
                                }}
                                placeholder="Enter your email address"
                                className={`w-full pl-10 pr-3 py-2 border ${
                                    error ? 'border-red-500' : 'border-gray-300'
                                } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7F55E0] focus:border-transparent transition-colors`}
                                disabled={isLoading}
                            />
                        </div>

                        {error && (
                            <div className="flex items-center gap-2 text-red-500">
                                <AlertCircle size={16} />
                                <p className="text-sm">{error}</p>
                            </div>
                        )}

                        <button
                            onClick={handleSubmit}
                            disabled={isLoading || !!error}
                            className={`w-full py-3 px-6 rounded-lg text-white font-medium text-lg
                                transition duration-300 ${
                                    isLoading || error
                                        ? 'bg-gray-400 cursor-not-allowed'
                                        : 'bg-[#7F55E0] hover:bg-[#6A44C0]'
                                }`}
                        >
                            {isLoading ? 'Sending...' : 'Send Reset Code'}
                        </button>

                        <button
                            onClick={() => navigate('/login')}
                            disabled={isLoading}
                            className="w-full text-[#7F55E0] hover:text-[#6A44C0] font-medium transition-colors"
                        >
                            Back to Login
                        </button>
                    </div>
                </div>
            </div>

            {isLoading && (
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[70]">
                    <div className="bg-white p-4 rounded-full">
                        <LoadingSpinner className="h-12 w-12 animate-spin" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ForgotPassword;
