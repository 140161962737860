import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import LandingPage from "../Pages/LandingPage";
import VerifyNewUserPage from "../Pages/VerifyNewUserPage";
import WhyPrevantiveCare from "../Pages/WhyPrevantiveCare";
import UpgradePlan from "../Pages/UpgradePlan";
import AskVetiQ from "../Pages/AskVetiQ";
import SignUpPage from "../Pages/SignUpPage";
import ForgotPassword from "../Component/ForgotPassword";
import VerifyForgotPasswordPage from "../Pages/VerifyForgotPasswordPage";
import ResetPassword from "../Component/ResetPassword";
import LoginForm from "../Component/LoginForm";
import PrivateRoute from "../Component/PrivateRoute"
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TermsOfUse from "../Pages/TermsOfUse";
import BuyVetiqSubscription from "../Component/BuyVetiqSubscription";
import PaymentStatus from "../Component/PaymentStatus";

function AppNavigation() {
  const location = useLocation();
  const hideFooterOnRoutes = ["/buy-vetiq-subscription","/ask-vetiq", "/verify-new-user", "/forgot-password", "/reset-password", "/login", '/verify-for-pass-req',"/payment-status/:uid/:merchantTransactionId"];
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <>
       <Header  isOpen={isOpen}  setIsOpen={setIsOpen}/>
         <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/why-pet-prevantive-care" element={<WhyPrevantiveCare />} />
        <Route path="/upgrade-plan" element={<UpgradePlan />} />
        <Route path="/ask-vetiq" element={<PrivateRoute><AskVetiQ /></PrivateRoute>} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/verify-new-user" element={<VerifyNewUserPage/>}/>
        <Route path="/verify-for-pass-req" element={<VerifyForgotPasswordPage/>}/>
        <Route path="/forgot-password" element={<ForgotPassword/>}/>
        <Route path="/reset-password" element={<ResetPassword/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/buy-vetiq-subscription" element={<PrivateRoute><BuyVetiqSubscription /></PrivateRoute>} />
        <Route path="/payment-status/:uid/:merchantTransactionId" element={<PaymentStatus />} />
        <Route path="*" element={<LandingPage />} />
      </Routes>
      {!hideFooterOnRoutes.includes(location.pathname) && <Footer />}
    </>
  );
}

export default AppNavigation
