import React, { useState, useContext } from "react";
import { ArrowLeft, Shield, ExternalLink } from "lucide-react";
import { UserContext } from "../App";
import { initiatePayment } from "../api/paymentApi";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../utils/LoadingSpinner";
import SnackBar from "../utils/SnackBar";

const BuyVetiqSubscription = () => {
  const navigate = useNavigate();
  const { User } = useContext(UserContext);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackBarVisible, setSnackBarVisible] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  // Show snackbar message
  const showSnackbar = (message, duration = 3000) => {
    setSnackMessage(message);
    setSnackBarVisible(true);
    setTimeout(() => {
      setSnackBarVisible(false);
      setSnackMessage("");
    }, duration);
  };

  // Handle payment initiation
  const handlePaymentClick = async () => {
    if (!User) {
      navigate('/login', { state: { from: '/upgrade-plan' } });
      return;
    }
    
    try {
      setIsLoading(true);
      const orderId = 'ORDER_' + Date.now();
      const amount = 159; // Amount in rupees
      
      console.log('Initiating payment with:', {
        orderId,
        amount,
        userId: User
      });
      
      const response = await initiatePayment(orderId, amount, User);
      console.log('Payment initiation response:', response);
      
      if (response?.success && response?.data.instrumentResponse.redirectInfo.url) {
        showSnackbar('Redirecting to payment page...');
          window.location.href = response?.data.instrumentResponse.redirectInfo.url;
      } else {
        throw new Error(response?.message || 'Payment initiation failed');
      }
    } catch (error) {
      console.error('Payment initiation failed:', error);
      showSnackbar(error?.message || "Payment initiation failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const PaymentSummary = () => (
    <div className="w-full max-w-md mx-auto bg-white rounded-lg p-5 md:p-6 md:mt-10 shadow-lg">
      <div className="space-y-5">
        <h2 className="text-xl md:text-2xl font-bold text-slate-900 pb-3 border-b">
          Order Summary
        </h2>

        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <span className="text-base md:text-lg text-gray-700">Preventive Care Plan</span>
            <span className="text-xl md:text-2xl font-bold text-[#7F55E0]">₹159</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-sm text-gray-600">Validity</span>
            <span className="text-sm text-gray-600">1 Month</span>
          </div>
          <div className="flex justify-between items-center pt-3 border-t">
            <span className="text-base md:text-lg font-bold text-gray-700">Total Amount</span>
            <span className="text-xl md:text-2xl font-bold text-[#7F55E0]">₹159</span>
          </div>
        </div>

        <button
          onClick={handlePaymentClick}
          disabled={isLoading}
          className={`w-full py-2.5 md:py-3 px-6 rounded-lg text-white font-medium text-base md:text-lg
            transition duration-300 ${
              isLoading
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-[#7F55E0] hover:bg-[#6A44C0] transform hover:-translate-y-0.5 hover:shadow-lg'
            }`}
        >
          {isLoading ? (
            <div className="flex items-center justify-center gap-2">
              <LoadingSpinner className="h-4 w-4 md:h-5 md:w-5" />
              <span>Processing...</span>
            </div>
          ) : (
            <div className="flex items-center justify-center gap-2">
              <span>Proceed to Payment</span>
              <ExternalLink size={16} />
            </div>
          )}
        </button>

        <div className="flex items-center justify-center gap-2 text-gray-600 text-xs md:text-sm">
          <Shield size={16} className="text-[#7F55E0]" />
          <span>Secure Payment via PhonePe</span>
        </div>

        <div className="bg-yellow-50 border border-yellow-200 p-3 rounded-lg">
          <p className="text-yellow-800 text-xs md:text-sm">
            You will be redirected to PhonePe's secure payment page. By proceeding, you agree to our terms and conditions.
          </p>
        </div>
      </div>
    </div>
  );

  // Main render with enhanced UI
  return (
    <div className="flex flex-col items-center p-4 md:pt-8 space-y-5 bg-slate-50 min-h-screen">
      {/* Snackbar */}
      {isSnackBarVisible && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-[60]">
          <SnackBar 
            isSnackBarVisible={isSnackBarVisible} 
            snackMessage={snackMessage}
          />
        </div>
      )}
      
      {/* Header - Mobile Only */}
      <div className="w-full max-w-6xl md:hidden">
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={() => navigate('/upgrade-plan')}
            className="text-gray-500 hover:text-gray-700 flex items-center gap-1 transition-colors"
            disabled={isLoading}
          >
            <ArrowLeft size={18} />
            <span className="text-sm">Back</span>
          </button>
          <h1 className="text-lg font-bold text-slate-900 ml-4">Complete Your Purchase</h1>
          <div className="w-8"></div> {/* Empty div for flex justify-between */}
        </div>
      </div>
      
      <div className="w-full max-w-6xl md:mt-8">
        <PaymentSummary />
      </div>
      
      {/* Loading Overlay */}
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-full shadow-xl">
            <LoadingSpinner className="h-10 w-10 md:h-12 md:w-12 animate-spin" />
          </div>
        </div>
      )}
    </div>
  );
};

export default BuyVetiqSubscription;
