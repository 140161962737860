import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { verifyPayment, updateSubscription } from '../api/paymentApi';
import LoadingSpinner from '../utils/LoadingSpinner';
import { CheckCircle, XCircle, RefreshCw, Home, ArrowLeft, ArrowRight } from 'lucide-react';
import { UserContext } from '../App';
import SnackBar from '../utils/SnackBar';

// Payment status constants
const PAYMENT_STATUS = {
  PROCESSING: "processing",
  SUCCESS: "success",
  PENDING: "pending",
  FAILED: "failed"
};

// Polling interval configuration (in milliseconds)
const POLLING_INTERVALS = {
  INITIAL_DELAY: 3000,
  FIRST_PHASE: 3000,   // Every 3 seconds for 30 seconds
  SECOND_PHASE: 6000,  // Every 6 seconds for 60 seconds
  THIRD_PHASE: 10000,  // Every 10 seconds for 60 seconds
  FOURTH_PHASE: 30000, // Every 30 seconds for 60 seconds
  FINAL_PHASE: 60000   // Every 60 seconds until timeout
};

const MAX_POLLS = 200; // Prevent infinite polling (approximately 20 minutes)

const PaymentStatus = () => {
    const { uid, merchantTransactionId } = useParams();
    const navigate = useNavigate();
    const { User } = useContext(UserContext);
    const [status, setStatus] = useState(PAYMENT_STATUS.PROCESSING);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSnackBarVisible, setSnackBarVisible] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [pollCount, setPollCount] = useState(0);

    const showSnackbar = (message, duration = 3000) => {
        setSnackMessage(message);
        setSnackBarVisible(true);
        setTimeout(() => {
            setSnackBarVisible(false);
            setSnackMessage('');
        }, duration);
    };

    const getPaymentStatus = async () => {
        if (!merchantTransactionId) {
            setStatus(PAYMENT_STATUS.FAILED);
            setErrorMessage('Invalid transaction ID');
            setIsLoading(false);
            return "INVALID_TRANSACTION";
        }

        try {
            const result = await verifyPayment(merchantTransactionId);
            
            if (result.success && result.data?.state === "COMPLETED") {
                const userIdToUpdate = uid || User;
                
                if (userIdToUpdate) {
                    try {
                        await updateSubscription(userIdToUpdate, 8000, 'premium');
                        setStatus(PAYMENT_STATUS.SUCCESS);
                        showSnackbar('Payment successful! Your subscription has been activated.');
                        return "PAYMENT_SUCCESS";
                    } catch (subscriptionError) {
                        console.error('Subscription update failed:', subscriptionError);
                        setStatus(PAYMENT_STATUS.PENDING);
                        setErrorMessage('Payment successful, but subscription activation is pending. Please contact support if this persists.');
                        showSnackbar('Subscription activation pending. Please wait...');
                        return "SUBSCRIPTION_PENDING";
                    }
                } else {
                    throw new Error('User ID not available');
                }
            } else if (result.data?.state === "PENDING") {
                setStatus(PAYMENT_STATUS.PENDING);
                setErrorMessage('Your payment is being processed. Please wait a moment.');
                return "PAYMENT_PENDING";
            } else {
                setStatus(PAYMENT_STATUS.FAILED);
                setErrorMessage(result.message || 'Payment verification failed. Please try again.');
                return "PAYMENT_FAILED";
            }
        } catch (error) {
            console.error('Payment verification error:', error);
            setStatus(PAYMENT_STATUS.FAILED);
            if (error.message.includes('User ID not available')) {
                setErrorMessage('Session expired. Please login and check your subscription status.');
            } else {
                setErrorMessage('Unable to verify payment status. Please contact support if payment was deducted.');
            }
            return "PAYMENT_ERROR";
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!merchantTransactionId) return;

        let statusCheckTimeout = null;
        let statusCheckInterval = null;
        
        const checkPaymentStatus = async () => {
            if (pollCount >= MAX_POLLS) {
                setStatus(PAYMENT_STATUS.FAILED);
                setErrorMessage('Payment verification timed out. Please check your account or try again.');
                
                if (statusCheckInterval) clearInterval(statusCheckInterval);
                return;
            }
            
            const status = await getPaymentStatus();
            
            // If payment is complete or failed, stop polling
            const finalStatuses = [
                "PAYMENT_SUCCESS", "PAYMENT_ERROR", "PAYMENT_FAILED",
                "INVALID_TRANSACTION", "SUBSCRIPTION_PENDING"
            ];
            
            if (finalStatuses.includes(status)) {
                if (statusCheckInterval) clearInterval(statusCheckInterval);
                return;
            }
            
            // Continue polling with appropriate interval
            setPollCount(prev => prev + 1);
            if (statusCheckInterval) clearInterval(statusCheckInterval);
            
            // Determine interval based on poll count
            let intervalTime;
            if (pollCount < 10) intervalTime = POLLING_INTERVALS.FIRST_PHASE;
            else if (pollCount < 20) intervalTime = POLLING_INTERVALS.SECOND_PHASE;
            else if (pollCount < 26) intervalTime = POLLING_INTERVALS.THIRD_PHASE;
            else if (pollCount < 28) intervalTime = POLLING_INTERVALS.FOURTH_PHASE;
            else intervalTime = POLLING_INTERVALS.FINAL_PHASE;
            
            statusCheckInterval = setInterval(checkPaymentStatus, intervalTime);
        };
        
        // First check after initial delay
        statusCheckTimeout = setTimeout(checkPaymentStatus, POLLING_INTERVALS.INITIAL_DELAY);
        
        return () => {
            if (statusCheckTimeout) clearTimeout(statusCheckTimeout);
            if (statusCheckInterval) clearInterval(statusCheckInterval);
        };
    }, [merchantTransactionId]);

    const handleRetry = () => {
        setPollCount(0);
        setIsLoading(true);
        getPaymentStatus();
    };

    return (
        <div className="min-h-screen bg-slate-50">
            {/* Snackbar */}
            {isSnackBarVisible && (
                <SnackBar 
                    isSnackBarVisible={isSnackBarVisible} 
                    snackMessage={snackMessage}
                />
            )}

            {/* Header - Mobile Only */}
            <div className="w-full md:hidden bg-white shadow-sm">
                <div className="max-w-6xl mx-auto px-4 py-4">
                    <div className="flex justify-between items-center">
                        <button
                            onClick={() => navigate('/upgrade-plan')}
                            className="text-gray-500 hover:text-gray-700 flex items-center gap-1 transition-colors"
                            disabled={isLoading}
                        >
                            <ArrowLeft size={18} />
                            <span className="text-sm">Back</span>
                        </button>
                        <h1 className="text-lg font-bold text-slate-900">Payment Status</h1>
                        <div className="w-8"></div>
                    </div>
                </div>
            </div>
            
            {/* Main Content - Centered Container */}
            <div className="fixed inset-0 flex items-center justify-center p-4 md:p-8">
                <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-6 md:p-8 overflow-y-auto max-h-[90vh]">
                    {isLoading ? (
                        <div className="text-center space-y-6">
                            <LoadingSpinner className="h-16 w-16 mx-auto text-[#7F55E0]" />
                            <div className="space-y-2">
                                <h2 className="text-xl md:text-2xl font-bold text-slate-900">
                                    Verifying Payment
                                </h2>
                                <p className="text-gray-600">
                                    Please wait while we confirm your payment status...
                                </p>
                            </div>
                        </div>
                    ) : status === PAYMENT_STATUS.SUCCESS ? (
                        <div className="text-center space-y-6">
                            <div className="w-20 h-20 bg-green-50 rounded-full flex items-center justify-center mx-auto">
                                <CheckCircle className="h-12 w-12 text-green-500" />
                            </div>
                            <div className="space-y-2">
                                <h2 className="text-2xl font-bold text-green-600">
                                    Payment Successful!
                                </h2>
                                <p className="text-gray-600">
                                    Thank you for subscribing to VetiQ Premium. You can now access all premium features.
                                </p>
                            </div>
                            <button
                                onClick={() => navigate('/ask-vetiq')}
                                className="w-full py-3 px-6 bg-[#7F55E0] text-white rounded-lg hover:bg-[#6A44C0] font-medium transition-all hover:-translate-y-0.5 hover:shadow-lg flex items-center justify-center gap-2"
                            >
                                <span>Continue to VetiQ</span>
                                <ArrowRight size={18} />
                            </button>
                        </div>
                    ) : status === PAYMENT_STATUS.PENDING ? (
                        <div className="text-center space-y-6">
                            <div className="w-20 h-20 bg-yellow-50 rounded-full flex items-center justify-center mx-auto">
                                <RefreshCw className="h-12 w-12 text-yellow-500 animate-spin" />
                            </div>
                            <div className="space-y-2">
                                <h2 className="text-2xl font-bold text-yellow-600">
                                    Payment Processing
                                </h2>
                                <p className="text-gray-600">
                                    {errorMessage || "Your payment is being processed. This may take a moment."}
                                </p>
                            </div>
                            <div className="space-y-3">
                                <button
                                    onClick={handleRetry}
                                    className="w-full py-3 px-6 bg-[#7F55E0] text-white rounded-lg hover:bg-[#6A44C0] font-medium transition-all hover:-translate-y-0.5 hover:shadow-lg flex items-center justify-center gap-2"
                                >
                                    <RefreshCw size={18} />
                                    <span>Check Again</span>
                                </button>
                                <button
                                    onClick={() => navigate('/upgrade-plan')}
                                    className="w-full py-3 px-6 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 font-medium transition-colors"
                                >
                                    Back to Plans
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="text-center space-y-6">
                            <div className="w-20 h-20 bg-red-50 rounded-full flex items-center justify-center mx-auto">
                                <XCircle className="h-12 w-12 text-red-500" />
                            </div>
                            <div className="space-y-2">
                                <h2 className="text-2xl font-bold text-red-600">
                                    Payment Failed
                                </h2>
                                <p className="text-gray-600">
                                    {errorMessage || "We couldn't process your payment. Please try again."}
                                </p>
                            </div>
                            <div className="space-y-3">
                                <button
                                    onClick={() => navigate('/upgrade-plan')}
                                    className="w-full py-3 px-6 bg-[#7F55E0] text-white rounded-lg hover:bg-[#6A44C0] font-medium transition-all hover:-translate-y-0.5 hover:shadow-lg"
                                >
                                    Try Again
                                </button>
                                <button
                                    onClick={() => navigate('/')}
                                    className="w-full py-3 px-6 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 font-medium transition-colors flex items-center justify-center gap-2"
                                >
                                    <Home size={18} />
                                    <span>Go to Home</span>
                                </button>
                            </div>
                        </div>
                    )}
                    
                    {!isLoading && (
                        <div className="mt-6 pt-4 border-t border-gray-200">
                            <p className="text-center text-xs text-gray-500">
                                Transaction ID: {merchantTransactionId}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PaymentStatus; 