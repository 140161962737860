import React, { useState, useRef, useContext, useEffect } from "react";
import { Send, Dog, Cat, PlusCircle, MessageCircle, Clock, Sparkles, AlertCircle, CreditCard, Info } from "lucide-react";
import Pressable from "../utils/Pressable";
import AddNewPetModal from '../Component/AddNewPetModal';
import { getAIResponse } from "../api/aiApi";
import { getAllPetProfiles } from "../api/petApi";
import { UserContext } from "../App";

const CreditsSection = ({ remainingCredits, onAddPet }) => (
  <div className="flex justify-between items-center mb-4 bg-white p-4 rounded-lg shadow-sm">
    <div className="flex items-center gap-2">
      <h2 className="text-xl font-semibold text-slate-800">Your Pets</h2>
      {typeof remainingCredits === 'number' && (
        <div className="flex items-center gap-1 bg-[#F0EBFF] text-[#7F55E0] px-3 py-1 rounded-full text-sm">
          <CreditCard size={16} />
          <span>{remainingCredits} credits left</span>
        </div>
      )}
    </div>
    <Pressable 
      onClick={onAddPet} 
      title="Add New Pet" 
      className="flex items-center gap-2 bg-[#7F55E0] text-white px-4 py-2 rounded-md hover:bg-[#6A44C0] transition-colors shadow-sm"
    >
      <PlusCircle size={18} />
      <span>Add Pet</span>
    </Pressable>
  </div>
);

const PetCard = ({ pet, isSelected, onClick }) => (
  <div
    onClick={onClick}
    className={`flex flex-col items-center justify-center p-1 px-4 rounded-md cursor-pointer transition-all ${
      isSelected
        ? 'border-2 border-[#7F55E0]'
        : 'border border-gray-200 hover:border-[#b799ff]'
    } bg-white`}
    
  >
    <div className={`flex items-center justify-center w-10 h-10 rounded-lg ${
      isSelected ? 'text-[#7F55E0]' : ' text-[#7F55E0]'
    }`}>
      {pet.pettype?.toLowerCase() === 'dog' ? (
        <Dog size={40} />
      ) : (
        <Cat size={40} />
      )}
    </div>
    <p className={`font-medium text-center text-sm mt-1 ${
      isSelected ? 'text-[#7F55E0]' : 'text-slate-800'
    } truncate w-full`}>
      {pet.petname}
    </p>
  </div>
);

const PetSelector = ({ pets, selectedPetId, onPetSelect }) => {
  const sortedPets = [...pets].sort((a, b) => (a.petProfileId === selectedPetId ? -1 : 1));

  return (
    <div className="flex gap-3 overflow-x-auto pb-3 px-1 scrollbar-hide">
      {sortedPets.map((pet) => (
        <PetCard
          key={pet.petProfileId}
          pet={pet}
          isSelected={selectedPetId === pet.petProfileId}
          onClick={() => onPetSelect(pet.petProfileId)}
        />
      ))}
    </div>
  );
};

const Button = ({ children, onClick, disabled }) => (
  <button 
    className={`p-2 rounded-full shadow-md transition duration-300 ${
      disabled 
        ? 'bg-gray-300 cursor-not-allowed' 
        : 'bg-[#7F55E0] hover:bg-[#6A44C0]'
    }`}
    onClick={onClick}
    disabled={disabled}
  >
    <div className="text-white">
      {children}
    </div>
  </button>
);

const EmptyState = ({ onAddPet }) => (
  <div className="flex flex-col items-center justify-center h-full text-center p-6">
    <div className="bg-[#F0EBFF] p-4 rounded-full mb-4 shadow-sm">
      <MessageCircle size={40} className="text-[#7F55E0]" />
    </div>
    <h3 className="text-xl font-semibold text-slate-800 mb-2">Start a conversation with VetiQ</h3>
    <p className="text-gray-600 mb-6 max-w-md">
      Select a pet or add a new one to begin asking questions about your pet's health and wellbeing.
    </p>
    {onAddPet && (
      <button 
        onClick={onAddPet}
        className="flex items-center gap-2 bg-[#7F55E0] text-white px-4 py-2 rounded-md hover:bg-[#6A44C0] transition-colors shadow-sm"
      >
        <PlusCircle size={18} />
        <span>Add Your First Pet</span>
      </button>
    )}
  </div>
);

const MessageBubble = ({ message, isUser }) => {
  const formattedTime = new Date().toLocaleTimeString([], { 
    hour: '2-digit', 
    minute: '2-digit'
  });

  return (
    <div className={`flex ${isUser ? "justify-end" : "justify-start"} mb-2.5`}>
      <div
        className={`max-w-[95%] md:max-w-[70%] px-4 py-3 rounded-2xl ${
          isUser
            ? "bg-[#7F55E0] text-white rounded-tr-none"
            : "bg-white border border-gray-200 text-slate-800 rounded-tl-none shadow-sm"
        }`}
      >
        <p className="text-[15px] leading-relaxed whitespace-pre-wrap">
          {message.text}
        </p>
        <span className={`text-xs mt-1 block text-right ${isUser ? 'text-white/80' : 'text-slate-500'}`}>
          {formattedTime}
        </span>
      </div>
    </div>
  );
};

const ThinkingBubble = () => (
  <div className="flex justify-start mb-2.5">
    <div className="max-w-[95%] md:max-w-[70%] px-4 py-3 rounded-2xl bg-white border border-gray-200 text-slate-800 rounded-tl-none shadow-sm">
      <div className="flex items-center space-x-1.5">
        <div className="w-2 h-2 bg-[#7F55E0] rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
        <div className="w-2 h-2 bg-[#7F55E0] rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
        <div className="w-2 h-2 bg-[#7F55E0] rounded-full animate-bounce" style={{ animationDelay: '600ms' }}></div>
      </div>
    </div>
  </div>
);

const SecondMiddle = ({ chatData, noPets, onAddPet, isLoading }) => {
  if (noPets) {
    return <EmptyState onAddPet={onAddPet} />;
  }

  return (
    <div className="w-full flex-1 pb-20">
      {chatData.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full text-center  w-full">
          <div className="bg-[#F0EBFF] p-4 rounded-full mb-4 shadow-sm">
            <Sparkles size={40} className="text-[#7F55E0]" />
          </div>
          <h3 className="text-xl font-semibold text-slate-800 mb-2">Ask VetiQ anything about your pet</h3>
          <p className="text-gray-600 mb-4 max-w-md">
            Get expert advice on nutrition, behavior, health concerns, and more for your furry friend.
          </p>
          <div className="bg-white border border-gray-200 p-4 rounded-lg max-w-md w-full shadow-sm">
            <h4 className="font-medium text-[#7F55E0] mb-2 flex items-center gap-2">
              <Info size={16} />
              Sample questions you can ask:
            </h4>
            <ul className="text-left text-gray-700 space-y-2">
              <li className="flex items-start gap-2">
                <span className="text-[#7F55E0] font-bold">•</span>
                <span>"What vaccines does my pet need?"</span>
              </li>
              <li className="flex items-start gap-2">
                <span className="text-[#7F55E0] font-bold">•</span>
                <span>"Is my pet's diet appropriate for their age?"</span>
              </li>
              <li className="flex items-start gap-2">
                <span className="text-[#7F55E0] font-bold">•</span>
                <span>"How can I help my pet with separation anxiety?"</span>
              </li>
              <li className="flex items-start gap-2">
                <span className="text-[#7F55E0] font-bold">•</span>
                <span>"What are the signs of a healthy pet?"</span>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="flex flex-col  py-4 w-full">
          {chatData.map((item, index) => (
            <MessageBubble 
              key={index} 
              message={item} 
              isUser={item.type === "question"} 
            />
          ))}
          {isLoading && <ThinkingBubble />}
        </div>
      )}
    </div>
  );
};

const ThirdBottom = ({ question, setQuestion, onSubmit, disabled }) => (
  <div className="fixed bottom-[74px] px-2 md:bottom-4 left-0 right-0 md:left-1/2 md:transform md:-translate-x-1/2 w-full max-w-6xl bg-white shadow-lg md:shadow-none border-t md:border border-gray-200 p-3 md:p-4 flex items-center gap-2 z-40 md:mx-4 md:rounded-lg">
    <textarea 
      className="w-full p-2 focus:outline-none resize-none overflow-hidden bg-transparent placeholder:text-gray-500" 
      placeholder={disabled ? "Please select a pet first..." : "Ask a question..."}
      value={question}
      onChange={(e) => setQuestion(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey && !disabled && question.trim()) {
          e.preventDefault();
          onSubmit();
        }
      }}
      disabled={disabled}
      rows={1}
      style={{ minHeight: '40px', maxHeight: '120px' }}
    ></textarea>
    <Button onClick={onSubmit} disabled={disabled || !question.trim()}>
      <Send size={20} />
    </Button>
  </div>
);

const AskVetiQ = () => {
  const { User } = useContext(UserContext);
  const [addNewPet, setAddNewPet] = useState(false);
  const [question, setQuestion] = useState("");
  const [chatData, setChatData] = useState([]);
  const [allPets, setAllPets] = useState([]);
  const [selectedPetId, setSelectedPetId] = useState(null);
  const [remainingCredits, setRemainingCredits] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const scrollViewRef = useRef(null);
  const [petAdded, setPetAdded] = useState(false);

  const fetchPetsData = async () => {
    try {
      const data = await getAllPetProfiles(User);
      if (data && data.length > 0) {
        setAllPets(data);
        setSelectedPetId(data[0].petProfileId);
      }
    } catch (error) {
      console.error('Failed to fetch pets:', error);
    }
  };

  useEffect(() => {
    fetchPetsData();
  }, [User]);

  useEffect(() => {
    if (petAdded) {
      fetchPetsData();
      setPetAdded(false);
    }
  }, [petAdded]);

  const selectedPet = allPets.find(pet => pet.petProfileId === selectedPetId);

  const scrollToBottom = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
    }
  };

  const handleSavePet = (petData) => {
    setAddNewPet(false);
    setPetAdded(true);
  };

  const startTheChat = async () => {
    if (!question.trim()) return;
    
    try {
      setIsLoading(true);
      
      const newChatData = [
        ...chatData,
        { type: "question", text: question },
      ];

      setChatData(newChatData);
      setQuestion("");

      setTimeout(scrollToBottom, 100);

      const result = await getAIResponse(question, selectedPet, chatData, User);
      const { text, remainingCredits } = result;

      let chunkedText = '';
      const chunkSize = 50;
      
      // Add the answer with initial empty text
      setChatData(prev => [...prev, { type: "answer", text: "" }]);
      
      for (let i = 0; i < text.length; i += chunkSize) {
        chunkedText += text.slice(i, i + chunkSize);

        setChatData((prevChatData) => {
          const updatedChatData = [...prevChatData];
          updatedChatData[updatedChatData.length - 1] = {
            ...updatedChatData[updatedChatData.length - 1],
            text: chunkedText,
          };
          return updatedChatData;
        });

        await new Promise(resolve => setTimeout(resolve, 100));
        setTimeout(scrollToBottom, 100);
      }

      setRemainingCredits(remainingCredits);
    } catch (err) {
      console.error(err.message);
      setChatData(prev => [...prev, { 
        type: "answer", 
        text: "Sorry, I couldn't process your request. Please try again." 
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center p-4 md:pt-20 bg-slate-50 min-h-screen relative">
      <div className="flex flex-col max-w-6xl w-full h-[calc(100vh-180px)] md:h-[calc(100vh-120px)] overflow-hidden">
        <CreditsSection 
          remainingCredits={remainingCredits} 
          onAddPet={() => setAddNewPet(true)} 
        />
        
        {allPets.length > 0 && (
          <div className="w-full mb-4">
            <PetSelector 
              pets={allPets} 
              selectedPetId={selectedPetId} 
              onPetSelect={setSelectedPetId} 
            />
          </div>
        )}

        <div className="flex-1 overflow-y-auto scrollbar-hide bg-[#F9F9F9] rounded-lg w-full shadow-sm" ref={scrollViewRef}>
          <SecondMiddle 
            chatData={chatData} 
            noPets={allPets.length === 0}
            onAddPet={() => setAddNewPet(true)}
            isLoading={isLoading}
          />
        </div>
        
        {addNewPet && (
          <AddNewPetModal uid={User} onClose={() => setAddNewPet(false)} onPetAdded={handleSavePet} />
        )}
      </div>

      <ThirdBottom 
        question={question} 
        setQuestion={setQuestion} 
        onSubmit={startTheChat}
        disabled={!selectedPetId || isLoading} 
      />
    </div>
  );
};

export default AskVetiQ;

