import React from "react";

const Pressable = ({className,title,onClick,disabled,icon})=>{
    return(
        <button disabled={disabled} onClick={onClick} className={className}>
           {icon && icon}{title && title}
      </button>
    )
}

export default Pressable;