const EmailValidation = async (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) {
    throw new Error("Email address is required");
  } else if (!emailRegex.test(email)) {
    throw new Error("Please enter a valid email address");
  }
  return true;
}

const PasswordValidation = async (password, confirmpassword) => {
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecial = /[@!#$%&*?\s]/.test(password);

  if (!password) {
    throw new Error("Password is required");
  }

  const errors = [];
  if (password.length < 8) errors.push("at least 8 characters");
  if (!hasUppercase) errors.push("one uppercase letter");
  if (!hasLowercase) errors.push("one lowercase letter");
  if (!hasNumber) errors.push("one number");
  if (!hasSpecial) errors.push("one special character (@!#$%&*?)");

  if (errors.length > 0) {
    throw new Error(`Password must contain ${errors.join(", ")}`);
  }

  if (confirmpassword !== undefined && password !== confirmpassword) {
    throw new Error("Passwords do not match");
  }

  return true;
}

const OnlyTextValidation = async (fields, details) => {
  const regex = /^[A-Za-z\s]*$/;
  for (let field of fields) {
    if (!details[field]) {
      throw new Error(`${field.charAt(0).toUpperCase() + field.slice(1)} is required`);
    }
    if (!regex.test(details[field])) {
      throw new Error(`${field.charAt(0).toUpperCase() + field.slice(1)} can only contain letters and spaces`);
    }
    if (details[field].trim().length < 3) {
      throw new Error(`${field.charAt(0).toUpperCase() + field.slice(1)} must be at least 3 characters`);
    }
  }
  return true;
}

const EmptyFieldValidation = async (requiredFields, details) => {
  const emptyFields = [];
  for (let field of requiredFields) {
    if (!details[field] || details[field].trim().length === 0) {
      emptyFields.push(field.charAt(0).toUpperCase() + field.slice(1));
    }
  }
  
  if (emptyFields.length > 0) {
    throw new Error(
      emptyFields.length === 1
        ? `${emptyFields[0]} is required`
        : `${emptyFields.slice(0, -1).join(", ")} and ${emptyFields.slice(-1)} are required`
    );
  }
  return true;
}

const CheckedValidation = async (isChecked) => {
  if (!isChecked) {
    throw new Error("Please accept the terms and conditions");
  }
  return true;
}

const OtpValidation = async (userotp) => {
  if (!userotp || userotp.trim().length === 0) {
    throw new Error("Please enter the verification code");
  }
  if (userotp.length !== 6) {
    throw new Error("Verification code must be 6 digits");
  }
  if (!/^\d{6}$/.test(userotp)) {
    throw new Error("Verification code can only contain numbers");
  }
  return true;
};

export { 
  PasswordValidation, 
  EmailValidation, 
  OtpValidation, 
  EmptyFieldValidation, 
  OnlyTextValidation, 
  CheckedValidation 
};