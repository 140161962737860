import React, { useContext, useState } from "react";
import { Menu, X, Home, Star, HandCoins, ScanHeart } from "lucide-react";
import Drawer from "../utils/CustomeDrawerContent";
import PoodlesLogo from "../utils/PoodlesLogo";
import { Link, NavLink } from "react-router-dom";
import { UserContext } from "../App";
import { deleteUserData } from '../localuserdata/indexedDB';
import { logoutUser } from '../api/authApi';
import { getUserData } from '../localuserdata/indexedDB';

const Header = ({ isOpen, setIsOpen }) => {
  const { User, setUser } = useContext(UserContext);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      const userData = await getUserData();
      if (userData) {
        await logoutUser(userData.uid, userData.sessionId);
      }
      await deleteUserData();
      setUser(null);
    } catch (error) {
      console.error('Logout error:', error);
      await deleteUserData();
      setUser(null);
    } finally {
      setIsLoggingOut(false);
    }
  };

  // Navigation links configuration for reuse
  const navLinks = [
    {
      to: "/",
      icon: <Home size={28} />,
      label: "Home"
    },
    {
      to: "/why-pet-prevantive-care",
      icon: <ScanHeart size={28} />,
      label: "Preventive Care"
    },
    {
      to: "/upgrade-plan",
      icon: <HandCoins size={28} />,
      label: "Upgrade"
    }
  ];

  return (
    <>
      <header className="w-full bg-white shadow-sm p-2 fixed top-0 z-50">
        <div className="md:w-3/4 mx-auto flex justify-between items-center">
          {/* Logo */}
          <div className="text-xl font-bold">
            <Link to="/"><PoodlesLogo width={120} height={40} /></Link>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex gap-6">
            {navLinks.map((link) => (
              <NavLink
                key={link.to}
                to={link.to}
                className={({ isActive }) => isActive ? "text-[#7F55E0] flex flex-col items-center gap" : "hover:text-gray-500 flex flex-col items-center gap"}
              >
                {link.icon} {link.label}
              </NavLink>
            ))}
          </nav>

          {/* Desktop Login button */}
          <div className="hidden md:flex gap-6">
            {User ?
              <button
                onClick={handleLogout}
                disabled={isLoggingOut}
                className="bg-[#323842] text-white px-8 py-2 rounded-md hover:bg-[#7F55E0] disabled:opacity-50"
              >
                {isLoggingOut ? 'Logging out...' : 'Logout'}
              </button>
              :
              <>
                <Link to="/login" className="bg-[#323842] text-white px-8 py-2 rounded-md hover:bg-[#7F55E0]">LogIn</Link>
                <Link to="/signup" className="bg-[#7F55E0] text-white px-8 py-2 rounded-md">Sign Up</Link>
              </>
            }
          </div>

          {/* Mobile Menu button */}
          <div className="md:hidden">
            {!isOpen && (
              <button onClick={() => setIsOpen(true)}>
                <Menu size={24} />
              </button>
            )}
          </div>
        </div>
        {/* Drawer Component */}
        {isOpen && <Drawer User={User} setIsOpen={setIsOpen} isOpen={isOpen} />}
      </header>

      {/* Mobile Bottom Navigation */}
      <nav className="md:hidden hover:text-gray-700 fixed bottom-0 left-0 right-0 bg-white shadow-[0_-2px_10px_rgba(0,0,0,0.1)] z-40 flex justify-around items-center py-2">
        {navLinks.map((link) => (
          <NavLink
            key={link.to}
            to={link.to}
            className={({ isActive }) => 
              `flex flex-col items-center px-2 py-1  ${isActive ? "text-[#7F55E0]" : "text-gray-600"}`
            }
          >
            {link.icon}
            <span className="text-sm mt-1">{link.label}</span>
          </NavLink>
        ))}
        {User ? (
          <button
            onClick={handleLogout}
            disabled={isLoggingOut}
            className="flex flex-col items-center px-2 py-1 text-gray-600"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
              <polyline points="16 17 21 12 16 7" />
              <line x1="21" y1="12" x2="9" y2="12" />
            </svg>
            <span className="text-xs mt-1">{isLoggingOut ? 'Logging out...' : 'Logout'}</span>
          </button>
        ) : (
          <Link to="/login" className="flex flex-col items-center px-2 py-1 text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
              <polyline points="10 17 15 12 10 7" />
              <line x1="15" y1="12" x2="3" y2="12" />
            </svg>
            <span className="text-xs mt-1">Login</span>
          </Link>
        )}
      </nav>

      {/* Add padding to the bottom of the page to account for the mobile navigation */}
      <div className="md:hidden h-16"></div>
    </>
  );
};

export default Header;