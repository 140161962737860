import React, { useState } from 'react';
import { X, Dog, Cat, Check, Info, Heart, Weight, Syringe, Clipboard, Dna } from 'lucide-react';
import Pressable from '../utils/Pressable';
import { addNewPetProfile } from '../api/petApi';
import LoadingSpinner from '../utils/LoadingSpinner';
import SnackBar from '../utils/SnackBar';

const PET_OPTIONS = {
  age: {
    Cat: ["kitten", "adult", "senior"],
    Dog: ["puppy", "adult", "senior"]
  },
  weight: {
    Cat: ["0-6Kg", "4-6Kg", "6+Kg"],
    Dog: ["0-10Kg", "10-25Kg", "25+Kg"]
  },
  vaccines: {
    common: ['Rabies', 'Parvovirus', 'Distemper'],
    Dog: ['Bordetella', 'Hepatitis', 'Influenza', 'Lyme Disease', 'Leptospirosis'],
    Cat: ['Feline Leukemia', 'FIV', 'Feline Infectious Peritonitis']
  }
};

const ModalHeader = ({ onClose }) => (
  <div className="flex justify-between items-center p-6 border-b border-gray-200">
    <div className="flex items-center gap-2">
      <Clipboard size={24} className="text-[#7F55E0]" />
      <h2 className="text-xl font-bold text-slate-900">Add New Pet Profile</h2>
    </div>
    <Pressable 
      onClick={onClose} 
      className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
      title="Close"
    >
      <X size={24} />
    </Pressable>
  </div>
);

const PetTypeSelector = ({ petType, onSelect }) => (
  <div className="flex gap-4 mb-6">
    {[
      { type: 'Cat', icon: <Cat size={24} /> },
      { type: 'Dog', icon: <Dog size={24} /> }
    ].map(({ type, icon }) => (
      <Pressable
        key={type}
        onClick={() => onSelect(type)}
        className={`flex-1 py-3 px-4 rounded-lg border flex items-center justify-center gap-2 transition-all duration-200 ${
          petType === type 
            ? 'bg-[#7F55E0] text-white border-[#7F55E0] shadow-md' 
            : 'bg-white hover:bg-gray-50 border-gray-200'
        }`}
        title={type}
      >
        {icon}
        <span className="font-medium">{type}</span>
      </Pressable>
    ))}
  </div>
);

const BasicInfo = ({ formData, onChange }) => (
  <div className="space-y-4">
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <Heart size={18} className="text-[#7F55E0]" />
      </div>
      <input
        type="text"
        placeholder="Pet Name"
        className="w-full p-3 pl-10 border rounded-lg focus:ring-2 focus:ring-[#7F55E0] focus:border-[#7F55E0] outline-none transition-all"
        value={formData.petname}
        onChange={(e) => onChange('petname', e.target.value)}
      />
    </div>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <Dna size={18} className="text-[#7F55E0]" />
      </div>
      <input
        type="text"
        placeholder="Breed"
        className="w-full p-3 pl-10 border rounded-lg focus:ring-2 focus:ring-[#7F55E0] focus:border-[#7F55E0] outline-none transition-all"
        value={formData.breed}
        onChange={(e) => onChange('breed', e.target.value)}
      />
    </div>
  </div>
);

const OptionGroup = ({ title, options, selected, onSelect, icon }) => (
  <div className="space-y-2">
    {title && (
      <div className="flex items-center gap-2">
        {icon}
        <h3 className="font-semibold text-slate-900">{title}</h3>
      </div>
    )}
    <div className="flex gap-4">
      {options.map(option => (
        <Pressable
          key={option}
          onClick={() => onSelect(option)}
          className={`flex-1 py-2 rounded-lg border transition-all duration-200 ${
            selected === option 
              ? 'bg-[#7F55E0] text-white border-[#7F55E0] shadow-sm' 
              : 'bg-white hover:bg-gray-50 border-gray-200'
          }`}
          title={option}
        >
          {selected === option && <Check size={16} className="inline-block mr-1" />}
          {option}
        </Pressable>
      ))}
    </div>
  </div>
);

const VaccineSelector = ({ vaccines, selectedVaccines, onToggle }) => (
  <div className="space-y-2">
    <div className="flex items-center gap-2">
      <Syringe size={18} className="text-[#7F55E0]" />
      <h3 className="font-semibold text-slate-900">Vaccinations</h3>
    </div>
    <div className="grid grid-cols-2 gap-2">
      {vaccines.map(vaccine => (
        <Pressable
          key={vaccine}
          onClick={() => onToggle(vaccine)}
          className={`p-2 rounded-lg border flex items-center gap-2 transition-all duration-200 ${
            selectedVaccines.includes(vaccine) 
              ? 'bg-[#7F55E0] text-white border-[#7F55E0]' 
              : 'bg-white hover:bg-gray-50 border-gray-200'
          }`}
          title={vaccine}
        >
          {selectedVaccines.includes(vaccine) && <Check size={16} />}
          <span className="text-sm">{vaccine}</span>
        </Pressable>
      ))}
    </div>
  </div>
);

const StepIndicator = ({ currentStep, totalSteps }) => (
  <div className="px-6 pt-4">
    <div className="flex items-center justify-between mb-4">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <React.Fragment key={index}>
          <div 
            className={`w-8 h-8 rounded-full flex items-center justify-center ${
              currentStep > index + 1 
                ? 'bg-green-500 text-white' 
                : currentStep === index + 1 
                  ? 'bg-[#7F55E0] text-white' 
                  : 'bg-gray-200 text-gray-500'
            }`}
          >
            {currentStep > index + 1 ? <Check size={16} /> : index + 1}
          </div>
          {index < totalSteps - 1 && (
            <div 
              className={`flex-1 h-1 ${
                currentStep > index + 1 ? 'bg-green-500' : 'bg-gray-200'
              }`}
            />
          )}
        </React.Fragment>
      ))}
    </div>
    <p className="text-center text-sm text-gray-500 mb-2">
      {currentStep === 1 ? 'Select Pet Type' : 
       currentStep === 2 ? 'Basic Information' : 
       'Health Details'}
    </p>
  </div>
);

const AddNewPetModal = ({ onClose, uid, onPetAdded }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackBarVisible, setSnackBarVisible] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [petType, setPetType] = useState('');
  const [formData, setFormData] = useState({
    petname: '',
    breed: '',
    pettype: '',
    gender: '',
    age: '',
    neutered: '',
    spayed: '',
    weight: '',
    vaccinated: '',
    vaccines: []
  });
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3;

  const showSnackbar = (message) => {
    setSnackMessage(message);
    setSnackBarVisible(true);
  };

  const handlePetTypeChange = (type) => {
    setPetType(type);
    setFormData({
      petname: '',
      breed: '',
      pettype: type,
      gender: '',
      age: '',
      neutered: type === 'Dog' ? '' : undefined,
      spayed: type === 'Cat' ? '' : undefined,
      weight: '',
      vaccinated: '',
      vaccines: []
    });
    setCurrentStep(2);
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleVaccineToggle = (vaccine) => {
    setFormData(prev => ({
      ...prev,
      vaccines: prev.vaccines.includes(vaccine)
        ? prev.vaccines.filter(v => v !== vaccine)
        : [...prev.vaccines, vaccine]
    }));
  };

  const handleSubmit = async () => {
    // Validate required fields
    if (!formData.petname || !formData.breed || !formData.pettype || !formData.gender || !formData.age || !formData.weight) {
      showSnackbar('Please fill in all required fields');
      return;
    }

    try {
      setIsLoading(true);
      
      // Prepare the request body
      const petData = {
        uid: uid,
        petData: {
          petname: formData.petname,
          breed: formData.breed,
          pettype: formData.pettype.toLowerCase(),
          gender: formData.gender,
          age: formData.age,
          weight: formData.weight,
          vaccinated: formData.vaccinated,
          vaccines: formData.vaccines,
          ...(formData.pettype === 'Dog' ? { neutered: formData.neutered } : { spayed: formData.spayed })
        }
      };

      await addNewPetProfile(uid, petData);
      
      // Show success message
      showSnackbar('Pet profile added successfully!');
      
      // Notify parent component that pet was added
      onPetAdded();
      
      // Close modal after a delay to allow user to see the success message
      setTimeout(() => {
        setSnackBarVisible(false);
        onClose();
      }, 2000);
      
    } catch (error) {
      console.error('Error adding pet:', error);
      showSnackbar(error.message || 'Failed to add pet profile');
      setIsLoading(false);
    }
  };

  const nextStep = () => {
    if (currentStep === 2) {
      // Validate basic info before proceeding
      if (!formData.petname || !formData.breed || !formData.gender || !formData.age) {
        showSnackbar('Please fill in all required fields');
        return;
      }
    }
    setCurrentStep(prev => Math.min(prev + 1, totalSteps));
  };

  const prevStep = () => {
    setCurrentStep(prev => Math.max(prev - 1, 1));
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-6">
            <div className="text-center mb-6">
              <p className="text-gray-600">Select your pet type to get started</p>
            </div>
            <PetTypeSelector petType={petType} onSelect={handlePetTypeChange} />
          </div>
        );
      case 2:
        return (
          <div className="space-y-6">
            <BasicInfo formData={formData} onChange={handleInputChange} />

            <OptionGroup
              title="Gender"
              icon={<Info size={18} className="text-[#7F55E0]" />}
              options={['Male', 'Female']}
              selected={formData.gender}
              onSelect={(value) => handleInputChange('gender', value)}
            />

            <OptionGroup
              title="Age"
              icon={<Info size={18} className="text-[#7F55E0]" />}
              options={PET_OPTIONS.age[petType]}
              selected={formData.age}
              onSelect={(value) => handleInputChange('age', value)}
            />

            <OptionGroup
              title={`Is your ${petType.toLowerCase()} ${petType === 'Dog' ? 'neutered' : 'spayed'}?`}
              icon={<Info size={18} className="text-[#7F55E0]" />}
              options={['Yes', 'No']}
              selected={formData[petType === 'Dog' ? 'neutered' : 'spayed']}
              onSelect={(value) => handleInputChange(petType === 'Dog' ? 'neutered' : 'spayed', value)}
            />
          </div>
        );
      case 3:
        return (
          <div className="space-y-6">
            <OptionGroup
              title="Weight"
              icon={<Weight size={18} className="text-[#7F55E0]" />}
              options={PET_OPTIONS.weight[petType]}
              selected={formData.weight}
              onSelect={(value) => handleInputChange('weight', value)}
            />

            <OptionGroup
              title="Is your pet vaccinated?"
              icon={<Syringe size={18} className="text-[#7F55E0]" />}
              options={['Yes', 'No']}
              selected={formData.vaccinated}
              onSelect={(value) => {
                handleInputChange('vaccinated', value);
                if (value === 'No') {
                  handleInputChange('vaccines', []);
                }
              }}
            />

            {formData.vaccinated === 'Yes' && (
              <VaccineSelector
                vaccines={[
                  ...PET_OPTIONS.vaccines.common,
                  ...PET_OPTIONS.vaccines[petType]
                ]}
                selectedVaccines={formData.vaccines}
                onToggle={handleVaccineToggle}
              />
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-[100] flex md:items-center justify-center">
      {isSnackBarVisible && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-[110]">
          <SnackBar 
            isSnackBarVisible={isSnackBarVisible} 
            snackMessage={snackMessage}
          />
        </div>
      )}
      
      {isLoading && (
        <div className="absolute z-[101] p-5 top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-80 rounded-full">
          <LoadingSpinner className="h-12 w-12 animate-spin" />
        </div>
      )}

      <div className={`relative w-full bg-white shadow-2xl
        md:w-[600px] lg:w-[700px]
        md:rounded-2xl rounded-t-2xl
        flex flex-col
        md:my-8 md:mx-auto
        max-h-[90vh] md:max-h-[85vh]
        transition-all duration-300 ease-in-out
        ${window.innerWidth >= 768 ? 'translate-y-0' : 'translate-y-0 mt-auto'}
      `}>
        <ModalHeader onClose={onClose} />

        {/* Progress indicator */}
        <StepIndicator currentStep={currentStep} totalSteps={totalSteps} />

        <div className="p-6 overflow-y-auto flex-1 scrollbar-hide">
          {renderStepContent()}
        </div>

        <div className="p-6 border-t border-gray-200 flex justify-between">
          {currentStep > 1 ? (
            <button 
              onClick={prevStep}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition-colors"
            >
              Back
            </button>
          ) : (
            <div></div> // Empty div to maintain layout
          )}
          
          {currentStep < totalSteps ? (
            <button 
              onClick={nextStep}
              className="px-4 py-2 bg-[#7F55E0] text-white rounded-md hover:bg-[#6A44C0] transition-colors shadow-sm"
              disabled={currentStep === 1 && !petType}
            >
              Continue
            </button>
          ) : (
            <button 
              onClick={handleSubmit}
              disabled={isLoading}
              className="px-4 py-2 bg-[#7F55E0] text-white rounded-md hover:bg-[#6A44C0] transition-colors shadow-sm flex items-center gap-2 disabled:opacity-70 disabled:cursor-not-allowed"
            >
              <span>Add Pet</span>
              {!isLoading && <Check size={16} />}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewPetModal; 