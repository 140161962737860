

export const TermsAndConditionData = [
    {
        heading: "User Eligibility:",
        details: "To use our platform, you must meet our eligibility criteria and comply with any additional requirements specified by us."
    },
    {
        heading: "Services Available:",
        details: "As a user, you can access various pet care services through our platform, including veterinary services, grooming, pet boarding, and more. By using our platform, you agree to abide by our policies, guidelines, and standards to ensure a safe and positive experience."
    },
    {
        heading: "User Responsibilities:",
        details: "- You agree to use the platform responsibly and comply with all applicable laws and regulations.\n- You are responsible for ensuring the well-being of your pets when availing services.\n- You agree to provide accurate and updated information on your profile and service requests.\n- You acknowledge that service providers on our platform operate independently and are responsible for the services they provide.\n- You agree to respect the privacy and rights of other users and service providers, refraining from any harassment or misconduct."
    },
    {
        heading: "Platform Usage:",
        details: "- You will use our platform solely for the purpose of accessing pet care services.\n- You agree not to engage in any fraudulent, deceptive, or illegal activities while using our platform.\n- You agree not to misuse or attempt to manipulate any ratings, reviews, or feedback mechanisms available on the platform."
    },
    {
        heading: "Intellectual Property:",
        details: "You acknowledge that all intellectual property rights related to our platform belong to the Company, and you agree not to use or reproduce any such intellectual property without our prior written consent."
    },
    {
        heading: "Liability and Indemnification:",
        details: "- You agree to indemnify and hold harmless the Company from any claims, damages, or liabilities arising from your use of our platform.\n- The Company is not liable for any indirect, incidental, special, or consequential damages arising out of your use of our platform.\n- The Company does not guarantee the availability or quality of services provided by third-party service providers.\n- If you engage in direct transactions with service providers outside the platform, we do not encourage such actions and will not be responsible for any issues, disputes, or losses resulting from such transactions."
    },
    {
        heading: "Termination:",
        details: "The Company reserves the right to terminate your access to our platform at any time for any reason, without prior notice."
    },
    {
        heading: "Modifications:",
        details: "The Company reserves the right to modify or update these Terms and Conditions at any time without prior notice. Continued use of the platform constitutes acceptance of the updated Terms and Conditions."
    },
    {
        heading: "Governing Law:",
        details: "This Agreement shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes shall be subject to the exclusive jurisdiction of the courts in Jaipur, [Jurisdiction]."
    },
    {
        heading: "Payment and Fees:",
        details: "- Users agree to pay the applicable fees for services booked through the platform.\n- All payments must be made through the platform, and direct transactions with service providers outside the platform are not permitted.\n- The Company reserves the right to update service fees with prior notice.\n- Refunds or disputes must be addressed through the platform's dispute resolution process."
    },
    {
        heading: "Contract between Users and Service Providers:",
        details: "By booking a service through the platform, you enter into a direct agreement with the service provider. Poodles PetCare is not a party to any service agreement and is not responsible for the quality or outcome of services provided."
    },
    {
        heading: "Non-Solicitation Clause:",
        details: "Users agree not to engage service providers outside the platform for similar services to ensure platform integrity. Breach of this clause may result in account suspension."
    },
    {
        heading: "Privacy and Data Protection:",
        details: "- We collect and process user data in accordance with our Privacy Policy.\n- Users agree to the collection and use of their data for service facilitation and improvement.\n- We implement security measures to protect user data, but we are not liable for unauthorized access due to external breaches."
    },
    {
        heading: "Dispute Resolution:",
        details: "- Users agree to resolve disputes through the platform’s dispute resolution mechanism before seeking legal recourse.\n- In case of unresolved disputes, the matter will be handled in accordance with the governing law jurisdiction."
    }
];
