import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { X, Mail, Lock, Eye, EyeOff, AlertCircle } from "lucide-react";
import SnackBar from "../utils/SnackBar";
import { EmailValidation, EmptyFieldValidation, PasswordValidation } from "../Functions/Validations";
import LoadingSpinner from "../utils/LoadingSpinner";
import { UserContext } from "../App";
import { loginUser } from '../api/authApi';
import { storeUserData } from '../localuserdata/indexedDB';
import { encrypt } from '../Functions/encryption';

const LoginForm = () => {
  const { setUser } = useContext(UserContext);
  const Navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackBarVisible, setSnackbarVisible] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [details, setDetails] = useState({
    email: "",
    password: ""
  });
  const [errors, setErrors] = useState({
    email: "",
    password: ""
  });

  const showSnackbar = (message, duration = 3000) => {
    setSnackMessage(message);
    setSnackbarVisible(true);
    setTimeout(() => {
      setSnackbarVisible(false);
      setSnackMessage("");
    }, duration);
  };

  const validateField = async (name, value) => {
    try {
      let error = "";
      if (!value) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
      } else {
        switch (name) {
          case "email":
            await EmailValidation(value);
            break;
          case "password":
            await PasswordValidation(value);
            break;
          default:
            break;
        }
      }
      setErrors(prev => ({ ...prev, [name]: error }));
      return !error;
    } catch (err) {
      setErrors(prev => ({ ...prev, [name]: err.message }));
      return false;
    }
  };

  const handleDetails = async (event) => {
    const { name, value } = event.target;
    setDetails(prev => ({
      ...prev,
      [name]: value
    }));
    await validateField(name, value);
  };

  const handleUserSignin = async () => {
    const { email, password } = details;
    try {
      // Validate all fields first
      const isEmailValid = await validateField("email", email);
      const isPasswordValid = await validateField("password", password);
      
      if (!isEmailValid || !isPasswordValid) {
        showSnackbar("Please fix all errors before continuing");
        return;
      }

      setIsLoading(true);
      await EmptyFieldValidation(["email", "password"], details);
      
      const result = await loginUser(email, password);
      const { accessToken, refreshToken, uid, sessionId } = result;

      const userData = {
        refreshToken,
        uid,
        sessionId,
        accessToken: encrypt(accessToken)
      };
      await storeUserData(userData);
      setUser(userData.uid);
      
      showSnackbar("Login successful!");
      
      setTimeout(() => {
        Navigate('/ask-vetiq', { replace: true });
      }, 1000);

    } catch (err) {
      if (err.message === "Email Not Verified! Please Verify Your Email Before Logging In!!!") {
        showSnackbar("Email verification required. Redirecting...");
        
        const { accessToken, refreshToken, uid, sessionId } = err.userData;
        const verificationUserData = {
          refreshToken,
          uid,
          sessionId,
          accessToken: encrypt(accessToken)
        };
        
        setTimeout(() => {
          Navigate("/verify-new-user", {
            replace: true,
            state: {
              nextScreen: "/ask-vetiq",
              email: email,
              uid: err.uid,
              userData: verificationUserData
            }
          });
        }, 1500);
        return;
      }
      showSnackbar(err.message || "Login failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      {isSnackBarVisible && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-[60]">
          <SnackBar 
            isSnackBarVisibale={isSnackBarVisible} 
            snackMessage={snackMessage}
          />
        </div>
      )}

      <div className="w-full md:w-2/4 bg-white shadow-2xl p-6 rounded-t-2xl md:rounded-lg fixed bottom-0 md:static md:transform-none transition-transform ease-in-out duration-300 h-4/5 md:h-auto">
        {/* Close Button */}
        <div className="flex justify-end">
          <button 
            onClick={() => Navigate('/')} 
            className="text-gray-500 hover:text-gray-700 transition-colors"
            disabled={isLoading}
          >
            <X size={24} />
          </button>
        </div>

        {/* Login Header */}
        <div className="text-center mb-8">
          <h2 className="text-2xl md:text-3xl font-bold text-slate-900 mb-2">Welcome Back!</h2>
          <p className="text-gray-600">
            Please sign in to your account to continue
          </p>
        </div>

        {/* Login Form */}
        <div className="space-y-6">
          <div className="space-y-1">
            <label className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail size={20} className="text-gray-500" />
              </div>
              <input
                required
                value={details.email}
                onChange={handleDetails}
                name="email"
                type="email"
                placeholder="Enter your email"
                className={`w-full pl-10 pr-3 py-2 border ${
                  errors.email ? 'border-red-500' : 'border-gray-300'
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7F55E0] focus:border-transparent transition-colors
                ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isLoading}
              />
            </div>
            {errors.email && (
              <div className="flex items-center gap-2 text-red-500 mt-1">
                <AlertCircle size={16} />
                <p className="text-sm">{errors.email}</p>
              </div>
            )}
          </div>

          <div className="space-y-1">
            <label className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Lock size={20} className="text-gray-500" />
              </div>
              <input
                required
                value={details.password}
                onChange={handleDetails}
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                className={`w-full pl-10 pr-10 py-2 border ${
                  errors.password ? 'border-red-500' : 'border-gray-300'
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7F55E0] focus:border-transparent transition-colors
                ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isLoading}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 hover:text-gray-700"
                disabled={isLoading}
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
            {errors.password && (
              <div className="flex items-center gap-2 text-red-500 mt-1">
                <AlertCircle size={16} />
                <p className="text-sm">{errors.password}</p>
              </div>
            )}
          </div>

          <div className="flex items-center justify-end">
            <button
              onClick={() => Navigate("/forgot-password")}
              className="text-[#7F55E0] hover:text-[#6A44C0] text-sm font-medium transition-colors"
              disabled={isLoading}
            >
              Forgot password?
            </button>
          </div>

          <button
            onClick={handleUserSignin}
            disabled={isLoading || Object.values(errors).some(error => error)}
            className={`w-full py-3 px-6 rounded-lg text-white font-medium text-lg
              transition duration-300 ${
                isLoading || Object.values(errors).some(error => error)
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-[#7F55E0] hover:bg-[#6A44C0] transform hover:-translate-y-0.5'
              }`}
          >
            {isLoading ? (
              <div className="flex items-center justify-center gap-2">
                <LoadingSpinner className="h-5 w-5" />
                <span>Signing in...</span>
              </div>
            ) : (
              'Sign in'
            )}
          </button>

          <div className="text-center">
            <p className="text-gray-600">
              Don't have an account?{' '}
              <button
                onClick={() => Navigate("/signup")}
                className="text-[#7F55E0] hover:text-[#6A44C0] font-medium transition-colors"
                disabled={isLoading}
              >
                Sign up
              </button>
            </p>
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-full">
            <LoadingSpinner className="h-12 w-12 animate-spin" />
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
