import React from "react";

function SnackBar({ isSnackBarVisible, snackMessage }) {
  const snackbarAnimationStyle = {
    transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
    opacity: isSnackBarVisible ? 1 : 0,
    transform: isSnackBarVisible ? "translateY(0)" : "translateY(20px)",
    visibility: isSnackBarVisible ? "visible" : "hidden",
  };

  return (
    <div className="fixed inset-x-0 bottom-20 md:bottom-10 flex items-center justify-center z-[9999] px-4">
      <div
        id="snackbar"
        style={snackbarAnimationStyle}
        className="w-full max-w-sm bg-black shadow-2xl p-4 rounded-md md:rounded-lg"
      >
        <p className="text-sm md:text-base text-center text-white break-words whitespace-pre-wrap">
          {snackMessage}
        </p>
      </div>
    </div>
  );
}

export default SnackBar;
